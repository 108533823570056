import React from "react";
import PropTypes from "prop-types";
import GlobalHelmet from "./global-helmet";

const LayoutEmpty = ({ children }) => {
  return (
    <>
      {children}
      <GlobalHelmet />
    </>
  );
};

LayoutEmpty.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutEmpty;
