import React, { Component } from "react";
import LayoutEmpty from "../components/layout-empty";
import SEO from "../components/seo";
import "./reset-password.scss";
import { navigate } from "gatsby";
import auth from "../lib/auth";
import api from "../api";
import TextField from "../components/form/textField";
import { waitTime } from "../lib/helpers";

export default class ResetPasswordPage extends Component {

  constructor (props) {
    super(props);

    this.state = {
      // form
      pass1: "",
      pass2: "",
      user_login: "",
      user_key: "",

      // UI
      show_password: false,
      submitting: false,
      success_reset: false
    };
  }

  componentDidMount () {

    // disable access for authenticated users
    if (auth.loggedIn) {
      return navigate("/");
    }

    const secretParamsFilled = this.state.user_login && this.state.user_key;
    if (!secretParamsFilled) {
      // check '?key=xxx&login=xxx' exists
      const params = new URLSearchParams(window.location.search);
      const queryParams = {};
      for (let p of params) queryParams[p[0]] = p[1];
      if (!queryParams.login || !queryParams.key) {
        return navigate("/");
      }

      // fill secret params
      this.setState({
        user_login: queryParams.login,
        user_key: queryParams.key
      });

      // redirect to self without query params
      if (process.env.NODE_ENV !== "development") {
        navigate("/reset-password", { replace: true });
      }
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  togglePasswordVisibility = () => {
    this.setState({ show_password: !this.state.show_password });
  };

  cancel = () => {
    navigate("/");
  };

  onSubmit = async e => {
    e.preventDefault();
    try {
      this.setState({ submitting: true });
      await api.auth.resetpassword({
        pass1: this.state.pass1,
        pass2: this.state.pass2,
        user_login: this.state.user_login,
        user_key: this.state.user_key
      });
      this.setState({ success_reset: true });

      // login
      await auth.login({
        username: this.state.user_login,
        password: this.state.pass1
      });

      // redirect after 2 sec
      await waitTime(2000);
      navigate("/account");

    } catch (e) {
      alert(e);
    } finally {
      this.setState({ submitting: false });
    }
  };

  render () {
    return (
      <LayoutEmpty>
        <SEO title="Reset password" />
        <div className="hero is-warning is-fullheight">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <div className="column is-narrow">

                  {!this.state.success_reset ?

                    <form className="box form reset-password-form" onSubmit={this.onSubmit}>
                      <h1>Please<br /> choose a new password</h1>
                      <br />


                      <div className="field has-addons field-password">
                        <div className="control is-expanded">
                          <TextField label="Your new password" id="pass1" name="pass1"
                            type={this.state.show_password ? "text" : "password"}
                            minLength={8}
                            value={this.state.pass1} onChange={this.handleChange} />
                        </div>
                        <div className="control">
                          <button type="button" className="show-pass" tabIndex={-1}
                            aria-label={this.state.show_password ? "hide password" : "show password"}
                            onClick={this.togglePasswordVisibility}>
                            <i className={`fas ${this.state.show_password ? "fa-eye-slash" : "fa-eye"}`} />
                          </button>
                        </div>
                      </div>


                      <TextField label="Retype new password" id="pass2" name="pass2"
                        type={this.state.show_password ? "text" : "password"}
                        minLength={8}
                        value={this.state.pass2} onChange={this.handleChange} />

                      <div className="control submit-control">
                        <button type="submit" className={`button is-primary is-uppercase is-fullwidth${this.state.submitting ? " is-loading" : ""}`}>
                          RESET PASSWORD
                        </button>
                      </div>
                      <br />
                      <div className="control">
                        <button type="button" onClick={this.cancel} className="button is-transparent is-fullwidth">
                          CANCEL
                        </button>
                      </div>

                      <button type="button" onClick={this.cancel} className="smile-modal--close" aria-label="close" />
                    </form>
                    :

                    <div className="box reset-password-done success-feedback">
                      <img src={require("../images/done.svg")} alt="" className="success-feedback--check" />
                      <h3>Password successfully reset.<br />Hooray!</h3>
                      <p>Hold on, you'll be directed in a few seconds.</p>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutEmpty>
    );
  }
}
